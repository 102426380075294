import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import thunk from 'redux-thunk';
import rootReducer from '../src/redux/reducers/index';

import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools({
  trace:true
})

export const store = createStore(rootReducer, composeEnhancers( applyMiddleware(thunk)));
// console.log(store.getState());
// const store = configureStore({ reducer: Reducers }, applyMiddleware(thunk));

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchInterval: false, staleTime: Infinity } }
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>

  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
