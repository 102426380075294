import React, { useState } from "react";
import { Button, Modal, Form, Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Logo from "../Assets/BetterAppIcon_PNG.png";

const AppDeleteProfile = () => {
  const [show, setShow] = useState(true);
  const [err, setErr] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleSubmit = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setErr(true);
    } else {
      setErr(false);
      swal({
        title: "Are you sure?",
        text: "You want to delete your account? once you delete the account then every piece of data will be deleted from our server",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Your account is deleted successfully", {
            icon: "success",
          });
          history.push("/login");
        } else {
          swal("Your account is safe!");
        }
      });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        // onHide={() => {
        //   setShow(false);
        // }}
        id="recoveryRoomChat"
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {/* <div className="chat-block mr-4"> */}
          {/* <input type="text" value={text} onChange={(e) => setText(e.target.value)} autoFocus placeholder="Write message" className='form-control rounded-pill chat-input' /> */}
          <div className="d-flex justify-content-center">
            <img src={Logo} className="w-[50px] h-[50px] mb-[20px]" />
          </div>
          <label>
            Enter the email address with which you are registered on the better
            app
          </label>
          <input
            type="text"
            className="border w-full p-1"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          />
          {err ? (
            <p className="text-[12px] text-[red]">Enter Valid Email</p>
          ) : (
            ""
          )}
          {/* </div> */}
          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              type="submit"
              variant="custom"
              size="sm"
              onClick={() => {
                handleSubmit();
              }}
            >
              Send
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppDeleteProfile;
