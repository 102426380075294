import React, { useEffect, useState } from 'react'
import './Header.css';
import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav, Form, NavDropdown, Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../Assets/BetterAppIcon_PNG.png'
import { LogOutUser } from '../../redux/actions/LogOutUser';
import swal from 'sweetalert';
import changePasscode from '../Assets/Header/change_password.png';
import blockedUser from '../Assets/Header/block_user.png';
import contactUs from '../Assets/Header/contact_us.png';
import terms_of_services from '../Assets/Header/terms_of_services.png';
import aboutApp from '../Assets/Header/about_unity.png';
import nearBy from '../Assets/Header/nearby.png';
import Link from '@mui/material/Link';
import Delete from '../Assets/Header/delete.png';
import logOut from '../Assets/Header/logout.png';
import { BiSearchAlt2 } from 'react-icons/bi'

//array to check more menu active
const moreDropdown = ['/change_password', '/blocked_users', '/contact-us', '/about', '/nearby_people'];

let intervalID = null;

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [notificationCount, setNotificationCount] = useState([])

  const handleLogout = () => {
    swal({
      // title: "Are you sure?",
      text: "Are you sure , you want to exit from Better?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(LogOutUser()).then(res => {
            if (res.ErrCode === 0) {
              localStorage.clear();
              history.push("/login");
            }
          })
        }
      })
  }

  const deleteUserAccount = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/CleanUser?UserID=${Number.parseInt(localStorage.getItem("userId"))}&Code=better@2020`, {
            method: "GET"
          })
            .then(res => res.json())
            .then(result => {
              if (result.ErrCode === 0) {
                swal("Your account has been deleted!", {
                  icon: "success",
                });
                localStorage.clear();
                history.push("/login");
              }
            })
            .catch(err => {
              console.log(err);
            })

        } else {
          swal("Your account is safe!");
        }
      });
  }
  const pathname = window.location.pathname;

  const notificationCountDta = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/GetPushNotificationDetails?UserID=${Number.parseInt(localStorage.getItem("userId"))}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(result => {
        setNotificationCount(result.data)

        if (result.ErrCode === 0) {
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {

    intervalID = setInterval(() => {
      notificationCountDta()
    }, 2000)


    return () => {
      clearInterval(intervalID)
    }
  }, [])

  return (

    <Navbar expand="lg" className='navbar-mainbg' sticky="top">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block align-top "
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto my-2 my-lg-0">
            <NavLink className="nav-link d-lg-none" to="/" exact>
              <i className="fas fa-home" ></i>Home
            </NavLink>
            <NavLink className="nav-link" to="/chat" >
              <i className="fas fa-comments"></i>Chats
            </NavLink>
            <NavLink className="nav-link position-relative" to="/web_notification_web" exact>
              <i className="fas fa-bell"></i>Notification &nbsp;
              {notificationCount.length ? notificationCount.map((v, i, arr) => (
                arr.length - 1 === i ?
                  <Badge bg="white" className='noti-count'>{v.Count === 0 ? "" :v.Count}</Badge> : "")) : ""}
            </NavLink>
            <NavLink className="nav-link" to="/my_profile" style={{ color: "white" }} exact>
              <i className="fas fa-user-circle"></i>Profile
            </NavLink>

            <NavDropdown title="More" id="collasible-nav-dropdown" className={`${moreDropdown.includes(pathname) ? 'active' : ''}`}>
              <NavDropdown.Item to="/changepassword" onClick={() => history.push("change_password")}> <img src={changePasscode} ></img> &nbsp;Change Password</NavDropdown.Item>
              <NavDropdown.Item to="blockuser" onClick={() => history.push("blocked_users")} ><img src={blockedUser} ></img>&nbsp;Blocked Users</NavDropdown.Item>
              <NavDropdown.Item to="ContactUs" onClick={() => history.push("/contact-us")} ><img src={contactUs} ></img>&nbsp;Contact Us</NavDropdown.Item>
              <NavDropdown.Item to="/about" onClick={() => history.push("about")} ><img src={aboutApp} ></img>&nbsp;About Better App</NavDropdown.Item>
              <Link href="https://betterapp.ca/index.php/terms-and-conditions-of-use/" target="_blank" style={{ cursor: 'pointer', color: "black", textDecoration: "none" }}><img className='d-inline-block ml-3' style={{ width: "20px", height: "20px" }} src={terms_of_services} ></img>&nbsp;Terms & Conditions</Link>
              <NavDropdown.Item to="/nearby" onClick={() => history.push("nearby_people")} > <img src={nearBy} ></img>&nbsp;NearBy</NavDropdown.Item>
              <NavDropdown.Item to="/deleteaccount" onClick={deleteUserAccount} > <img src={Delete} ></img>&nbsp;Delete Account</NavDropdown.Item>
              <NavDropdown.Item to="/logout" onClick={handleLogout}><img src={logOut} ></img>&nbsp;Logout</NavDropdown.Item>
            </NavDropdown>
            <NavLink className="nav-link ml-3" to="/nearby_people" style={{ color: "white" }} exact>
              <BiSearchAlt2 size={25} />
            </NavLink>
          </Nav>

          <NavLink className="nav-link" to="/my_profile" style={{ color: "white" }} exact>
            <img src={userData && userData.userDetails.FullImageUrl} className="rounded-circle float-left mr-2 overflow-hidden" style={{ width: '30px', height: '30px' }}></img> {userData && userData.userDetails.Username}
          </NavLink>

        </Navbar.Collapse>
      </Container>
    </Navbar>

  )
}
export default Header;