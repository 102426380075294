import { GET_POST, GET_STORY, GET_NOTIFICATION, GROUP_DATA, BIRTHDAYS_DATA, USERS_DATA, LGBTQ_MEETINGS, GET_LOGIN_USER_DETAIL, FOLLOWER_LIST, FOLLOWING_LIST, NEARBY_PEOPLE, SET_PHOTO, FILE_NAME, FEED_TYPE, GET_FELLOWSHIP, MCQ_QUESTION_DATA, PARTICULAR_GROUP_DETAIL, GET_MESSAGE_DETAILS } from "../constants/index";

const initialState = {
    currentUserPosts: [],
    currentUserFollowingStory: null,
    currentGroupsData: null,
    currentBirthdays: null,
    getFellowShipList: null,
    currentUsersData: null,
    currentLGBTQMeetings: null,
    logInUserDetail: null,
    followerList: null,
    followingList: null,
    currentUserNearPeopleData: null,
    base64String: "",
    fileName: "",
    MCQ: null,
    feedType: null,
    Likes: 0,
    DisLikes: 0,
    CommentOfPost: [],
    groupDetail: [],
    Messages: [],
}

export const posts = (state = initialState, action) => {
    switch (action.type) {
        case GET_POST:
            return {
                ...state,
                currentUserPosts: [...state.currentUserPosts, ...action.currentUserPosts]
            }

        case GET_STORY:
            return {
                ...state,
                currentUserFollowingStory: action.currentUserFollowingStory
            }
        case PARTICULAR_GROUP_DETAIL:
            return {
                ...state,
                groupDetail: [...state.groupDetail, ...action.groupDetail]
            }
        case MCQ_QUESTION_DATA:
            return {
                ...state,
                MCQ: action.MCQ
            }
        case GET_MESSAGE_DETAILS:
            return {
                ...state,
                Messages: action.Messages.reverse()
            }
        case GET_LOGIN_USER_DETAIL:
            return {
                ...state,
                logInUserDetail: action.logInUserDetail
            }    
        case FOLLOWER_LIST:
            return {
                ...state,
                followerList: action.followerList
            }
        case FOLLOWING_LIST:
            return {
                ...state,
                followingList: action.followingList
            }

        case SET_PHOTO:
            return {
                ...state,
                base64String: action.payload
            }
        case FILE_NAME:
            return {
                ...state,
                fileName: action.payload
            }
        case FEED_TYPE:
            return {
                ...state,
                feedType: action.payload
            }
        case GET_NOTIFICATION:
            return {
                ...state,
                currentUserNotification: action.currentUserNotification
            }
        case GROUP_DATA:
            return {
                ...state,
                currentGroupsData: action.currentGroupsData
            }
        case GET_FELLOWSHIP:
            return {
                ...state,
                getFellowShipList: action.getFellowShipList
            }
        case BIRTHDAYS_DATA:
            return {
                ...state,
                currentBirthdays: action.currentBirthdays
            }
        case USERS_DATA:
            return {
                ...state,
                currentUsersData: action.currentUsersData
            }
        case LGBTQ_MEETINGS:
            return {
                ...state,
                currentLGBTQMeetings: action.currentLGBTQMeetings
            }
        case NEARBY_PEOPLE:
            return {
                ...state,
                currentUserNearPeopleData: action.currentUserNearPeopleData
            }
        default:
            return state;
    }
}