import React from 'react'
import { Nav, Modal, Button, Form } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './SideNavBar.module.css'
import './styles.css'
import recoveryWall from '../Assets/sidebar/wall@3x.png';
import recoveryCapital from '../Assets/sidebar/plan@3x.png';
import recoveryScore from '../Assets/sidebar/capital_plan.png';
import recoveryRoom from '../Assets/sidebar/recoveryclock.svg';
import supportCircle from '../Assets/sidebar/supportcircle.png';
import peopleNear from '../Assets/sidebar/nearby.png';
import marketPlace from '../Assets/sidebar/classified.png';
import recoveryBirth from '../Assets/sidebar/recoveryclock.png';
import speakerTapes from '../Assets/sidebar/speakertapes.png';
import Meetings from '../Assets/sidebar/meetings.png';
import Groups from '../Assets/sidebar/groups.png';
import stepWork from '../Assets/sidebar/recoveryclock@3x.png';
import onlineCoaching from '../Assets/sidebar/online_class@3x.png';
import Lgbtq from '../Assets/sidebar/lgbt_support@3x.png';
import getHelp from '../Assets/sidebar/help@3x.png';
import leaderBoard from '../Assets/sidebar/leader_board@3x.png';

function SideNavBar() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [russelRecoveryProgramModalShow, setRusselRecoveryProgramModalShow] = React.useState(false);
  const [workSheetStepsModalShow, setWorkSheetStepsModalShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const { state } = useLocation();

  const group = () => {
    setOpen(false);
    history.push({ pathname: '/group', state: { active: 12 } })
  }

  return (
    <>
      <div className={styles.sidebarBg}>
        <Nav className="flex-column sidebar" variant='pills' activeKey={`link-${state?.active || ''}`} >
          <Nav.Link onClick={() => history.push({ pathname: '/', state: { active: 1 } })} eventKey="link-1"><img src={recoveryWall}></img> &nbsp;My Recovery Wall</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/my_recovery_capital_score', state: { active: 2 } })} eventKey="link-2"><img src={recoveryCapital}></img> &nbsp;My Recovery Capital Score</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/my_recovery_capital_plan', state: { active: 3 } })} eventKey="link-3"><img src={recoveryScore}></img> &nbsp;My Recovery Capital Plan</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/recovery_rooms', state: { active: 4 } })} eventKey="link-4"><img src={recoveryRoom}></img> &nbsp; Recovery Rooms</Nav.Link>
          {/* <Nav.Link onClick={() => setOpen(false)} eventKey="link-5">Safety Net</Nav.Link> */}
          <Nav.Link onClick={() => { history.push({ pathname: "/support_circle", state: { active: 6 } }) }} eventKey="link-6"><img src={supportCircle}></img> &nbsp;Support Circles</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/nearby_people', state: { active: 7 } })} eventKey="link-7"> <img src={peopleNear}></img> &nbsp;People Nearby</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/marketplace', state: { active: 8 } })} eventKey="link-8"><img src={marketPlace}></img> &nbsp;Marketplace</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/my_recovery_birthday', state: { active: 9 } })} eventKey="link-9"><img src={recoveryBirth}></img> &nbsp;My Recovery Birthday</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/speaker_tapes', state: { active: 10 } })} eventKey="link-10"><img src={speakerTapes}></img> &nbsp;Speaker Tapes</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/meetings', state: { active: 11 }})} eventKey="link-11"><img src={Meetings}></img> &nbsp;Meetings</Nav.Link>
          <Nav.Link onClick={() => group()} eventKey="link-12"><img src={Groups}></img> &nbsp; My Groups</Nav.Link>
          <Nav.Link 
          // onClick={() => history.push({ pathname: '/leader_boards_games_users_list', state: { active: 21 } })} 
          onClick={()=>history.push({pathname:"/recovery_celebrations",state:{active:23}})}
          eventKey="link-23"
          ><img src={leaderBoard}></img> &nbsp;Recovery Celebrations</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/step_work', state: { active: 13 } })} eventKey="link-13"><img src={stepWork}></img> &nbsp;Step Work</Nav.Link>

          <Nav.Link onClick={() => history.push({ pathname: '/online_coaching', state: { active: 19 } })} eventKey="link-19"><img src={onlineCoaching}></img> &nbsp;Online Coaching</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/lgbtq', state: { active: 22 } })} eventKey="link-22"><img src={Lgbtq}></img> &nbsp;LGBTQ Support</Nav.Link>
          <Nav.Link href="https://www.myrecoveryplan.ca/get-help/" eventKey="link-20"><img src={getHelp}></img> &nbsp;Get Help Now</Nav.Link>
          <Nav.Link onClick={() => history.push({ pathname: '/leader_boards_games_users_list', state: { active: 21 } })} eventKey="link-21"><img src={leaderBoard}></img> &nbsp;Leader Boards Games</Nav.Link>
          

        </Nav>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <TwellModalShow
          show={modal}
          onHide={() => setModal(false)}
        />

        <RusselRecoveryProgram
          show={russelRecoveryProgramModalShow}
          onHide={() => setRusselRecoveryProgramModalShow(false)}
        />


        <WorkSheetSteps
          show={workSheetStepsModalShow}
          onHide={() => setWorkSheetStepsModalShow(false)}
        />
      </div>
    </>
  )
}


function MyVerticallyCenteredModal(props) {
  const [stepOne, setStepOne] = React.useState({ ques: 1, answer: '', step: 1 });
  const [situation, setSituation] = React.useState({ ques: 2, answer: '', step: 1 });
  const [selfThought, setSelfThought] = React.useState({ ques: 3, answer: '', step: 1 });
  const [unmanageability, setUnmanageability] = React.useState({ ques: 4, answer: '', step: 1 });

  const history = useHistory();

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Stop form submit
    history.push({
      pathname: '/step_work_forms',
      state: { stepOne, situation, selfThought, unmanageability }
    })
  }


  return (

    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='my-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Step Exercise
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-dialog-scrollable'>

        <form>
          <div className={styles.formInformation}>
            <p>You can review these questions and reflect, type in the space provided or find a pen and paper and do it old school</p>
          </div>

          <div className={styles.firstInput}>
            <h4>Step 1</h4>
          </div>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Describe a situation in your life cousing fear or any other concern</Form.Label>
            <Form.Control
              type="text"
              required
              onChange={(e) => setStepOne({ ...stepOne, answer: e.target.value })}
            />
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Explain how you are powerless in this situation</Form.Label>
            <Form.Control
              type="text"
              required
              onChange={(e) => setSituation({ ...situation, answer: e.target.value })}
            />
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>List your thought, behaviour, feeling and self talk</Form.Label>
            <Form.Control
              type="text"
              required
              onChange={(e) => setSelfThought({ ...selfThought, answer: e.target.value })}
            />
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Explain the unmanageability it causes you in your life. Give examples</Form.Label>
            <Form.Control
              type="text"
              required
              onChange={(e) => setUnmanageability({ ...unmanageability, answer: e.target.value })}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button type='submit' className="bg-white text-black b-none" onClick={(e) => handleFormSubmit(e)} >Save And Next</Button>
      </Modal.Footer>
    </Modal>

  );
}




function TwellModalShow(props) {
  const [twellSetepInput, setTwellSetepInput] = React.useState({ ques: 1, answer: '', step: 1 });
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    history.push({
      pathname: '/twell_steps_one',
      state: { twellSetepInput }
    })
  }


  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='my-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Step Exercise
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-dialog-scrollable'>

        <form>
          <div className={styles.formInformation}>
            <p>
              When we work the program, we are living the steps daily.
              This gives us experience in applying spiritual principles.
              We must use what we learn or we will lose it, no matter how
              long we have been in recovery.
            </p>
          </div>

          <div className={styles.firstInput}>
            <h4>Step One</h4>
            <p>
              Admitting we were powerless, that our lives had become unmanageable.
            </p>

            <p>
              Identify a situation or problem in your life that is causing concern,
              resentment, fear, anxiety, anger, or sadness. Be specific, brief, and honest.
              Describe your powerlessness and the resulting unmanageability.
              Get help from your support group or sponsor if necessary. For Example:
            </p>
          </div>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="text-center">
              I am jammed in about my ex-wife's refusal to allow me to see my kids.
              I am powerless over her choices. I am feeling crazy, angry and defeated.
            </Form.Label>
            <Form.Control
              type="text"
              required
              onChange={e => setTwellSetepInput({ ...twellSetepInput, answer: e.target.value })}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-white text-black' onClick={(e) => handleSubmit(e)}>Next</Button>
      </Modal.Footer>
    </Modal>
  );
}



function RusselRecoveryProgram(props) {
  const [stepOne, setStepOne] = React.useState('');
  const [situation, setSituation] = React.useState('');
  const [selfThought, setSelfThought] = React.useState('');

  const history = useHistory();

  const handleFormSubmit = (e) => {
    history.push({
      pathname: '/russel_recovery_program_first',
      state: { stepOne, situation, selfThought }
    })
  }


  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='my-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Step Exercise
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-dialog-scrollable'>

        <form>
          <div className={styles.formInformation}>
            <p>You can review these questions and reflect, type in the space provided or find a pen and paper and do it old school</p>
          </div>

          <div className={styles.firstInput}>
            <h4>Step 1: Are you a bit fucked?</h4>
          </div>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What do I want to change?</Form.Label>
            <Form.Control
              type="text"
              required
              value={stepOne}
              onChange={(e) => setStepOne(e.target.value)}
            />
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What pain or fear do I associate with change in this area?</Form.Label>
            <Form.Control
              type="text"
              required
              value={situation}
              onChange={(e) => setSituation(e.target.value)}
            />
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What pleasure am I getting out of not changing?</Form.Label>
            <Form.Control
              type="text"
              required
              value={selfThought}
              onChange={(e) => setSelfThought(e.target.value)}
            />
          </Form.Group>

        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-white text-black" onClick={() => handleFormSubmit()}>Next</Button>
      </Modal.Footer>
    </Modal>
  );
}



function WorkSheetSteps(props) {
  const history = useHistory();
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='my-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Step Exercise
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-dialog-scrollable'>

        <form>
          <div className={styles.formInformation}>
            <h3>A Guide to Working the 12 Steps</h3>
            <p>
              To a newcomer, a 12-step program can be overwhelming. You may look at dramatic concepts
              such as making amends or reaching a spiritual awakening and wonder what that means in
              practical terms. Your first source for the substantive content of each step should be
              your sponsor. Do not work the steps without input from them and possibly from the rest
              of your group as well. The literature specific to your group is also valuable resources
              in this process.

              The process is called "working the steps" for a reason. Each step requires exhaustive mental
              work alongside practical steps. The 12-steps are designed to help us better understand where
              our addiction stems from and what we can do about it. This requires a great deal of
              introspection and soul searching. It is for this reason that focused questions are a crucial
              element in the equation. If you work the steps, listen to your sponsor, and take your recovery
              seriously, your life will improve immeasurably. Answering these questions as you reach the
              appropriate step, will help you along that process.

              This is not meant as a comprehensive source of questions relevant to your work on the 12
              steps. Instead, we advise that you show the questions to your sponsor and decide together
              if they are appropriate for you. Answering these questions does not replace the work you
              do with your sponsor, attending meetings, using official literature, and daily prayer and
              meditation. Instead, it is meant to complement the traditional elements and assist you as
              you improve your life by working the steps.</p>
          </div>


        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-white text-black' onClick={() => history.push('./twell_steps_worksheet_one')} >Next</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default SideNavBar;