import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loading from '../Components/Loading/Loading'
// import Step_work from '../Components/More/Step_Work/Step_work'
// import Notification from '../Components/Push_Notification/Notification'
// import RussellBrand from '../Components/StepWorkForm/RussellBrand'
// import RusselRecoveryOne from '../Components/StepWorkForm/RussellBrand/RusselRecoveryOne'
// import RusselRecoveryThree from '../Components/StepWorkForm/RussellBrand/RusselRecoveryThree'
// import RusselRecoveryTwo from '../Components/StepWorkForm/RussellBrand/RusselRecoveryTwo'
// import StepOne from '../Components/StepWorkForm/StepOneTwoTh/StepOne'
// import StepOneTwoTh from '../Components/StepWorkForm/StepOneTwoTh/FormOne'
// import TwelveSteps from '../Components/StepWorkForm/TwelveStep/index';
// import TwellStepsWorkSheet from '../Components/StepWorkForm/TwelveStepsWorkSheet/index'
// import HomeScreen from '../Screens/HomeScreen/HomeScreen'
// import ProfileScreen from '../Screens/ProfileSreen/ProfileScreen'
// import Nearby_People_Screen from '../Screens/Nearby_People_Screen/Nearby_People_Screen'
// import LeaderBoardScreen from '../Screens/LeaderBoardScreen/LeaderBoardScreen'
// import My_Recovery_Capital_Score_Screen from '../Screens/My_Recovery_Capital_Score_Screen/My_Recovery_Capital_Score_Screen'
import Login from '../Components/Login/Login';
import SignUp from '../Screens/auth/signUp/SignUp.js';
// import ForgotPassword from '../Screens/auth/forgotPassword/ForgotPassword'
// import MeetingRoomsScreens from '../Screens/MeetingRoomsScreen/MeetingRoomsScreen'
// import MeetingJoin from '../Components/Meeting/MeetingJoin'
// import MeetingChannel from '../Components/Meeting/MeetingChannel'
// import ContactUs from '../Components/contact/ContactUs'
// import BlockedUsers from '../Components/block/BlockedUsers'
// import ChangePassword from '../Components/change_password/ChangePassword'
// import SpeakerCategory from '../Components/speaker_tapes/SpeakerCategory'
// import SpeakerTape from '../Components/speaker_tapes/SpeakerTape'
// import Tape from '../Components/speaker_tapes/Tape'
// import RecoveryBirthday from '../Components/recovery_birthday/RecoveryBirthday'
// import MarketplaceCategory from '../Components/marketplace/MarketplaceCategory'
// import MarketplaceSubCategory from '../Components/marketplace/MarketplaceSubCategory'
// import OnlineCoaching from '../Components/coach/OnlineCoaching'
// import RecoveryCapitalPlan from '../Components/my_recovery_capital_plan/RecoveryCapitalPlan'
// import AddActivityGoal from '../Components/my_recovery_capital_plan/AddActivityGoal'
// import HistoryActivityGoal from '../Components/my_recovery_capital_plan/HistoryActivityGoal'
// import Step_Three from '../Components/StepWorkForm/StepOneTwoTh/FormTwo'
// import VideoCall from '../Screens/MeetingRoomsScreen/VideoCall'
// import MyGroupScreen from '../Screens/MyGroupScreen/MyGroupScreen'
// import SupportCircle from '../Screens/SupportCircle/SupportCircle'
// import LGBTQ from '../Screens/LGBTQ/LGBTQ'
// import SupportCircleCreate from '../Screens/SupportCircle/SupportCircleCreate';
import PrivateRoute from '../Screens/auth/PrivateRoute';
import { Spinner } from 'react-bootstrap';
// import TermsAndConditions from '../Screens/TermsAndConditions/TermsAndConditons';
// import MyGroupScreen from '../Screens/MyGroupScreen/MyGroupScreen';
// import MyGroupScreen from '../Screens/MyGroupScreen/MyGroupScreen'
// import MyGroupCard from '../Components/MyGroup/MyGroupCard'
// import PrivacyPolicy from '../Screens/PrivacyPolicy/PrivacyPolicy';
// import MyProfileScreen from '../Screens/MyProfileScreen.js/MyProfileScreen'
// import LeaderBoardGamesDetails from '../Components/Leader_boards_games/Leader_boards_games_list/LeaderBoardGamesDetails'
// import Followerlist from '../Components/GetFollowerListOfUser/Followerlist'
// import GetFollowingListOfUser from '../Components/GetFollowingListOfUser/GetFollowingListOfUser'
// import App from '../Components/src/components/App'
// import AboutBetterApp from '../Components/AboutBetterApp/AboutBetterApp'
// import DetailAboutCategory from '../Components/marketplace/DetailAboutCategory'
// import CreateClassified from '../Components/marketplace/CreateClassified'
// import AddPost from '../Components/Profile/Add_Post/AddPost'
// import EditProfile from '../Components/Profile/EditProfile/EditProfile'
// import FellowShipCheckBox from '../Components/Profile/FellowShipCheckBox/FellowShipCheckBox'
// import DailyJournal from '../Screens/DailyJournal/DailyJournal'
// import EditGoal from '../Components/my_recovery_capital_plan/EditActivityGoal'
// import SupportCircleChat from '../Screens/SupportCircle/SupportCircleChat'
// import FollowingUserPost from '../Components/Recovery_Wall/FollowingUserPost'
// import SupportCircleGroupDetails from '../Screens/SupportCircle/SupportCircleGroupDetails'
// import GroupList from '../Components/MyGroup/GroupList'
// import JoinGroup from '../Components/MyGroup/JoinGroup'
// import GroupPost from '../Components/MyGroup/GroupPost'
// import SponseesAndSponsors from '../Components/StepWorkForm/SponseesAndSponsors'
// import ChatList from '../Components/Chat/ChatList'
// import Message from '../Components/Chat/Message'
// import Meetings from '../Components/Meetings/Meeting_index';
// import LocateMeetings from '../Components/Meetings/LocateMeetings';
// import SearchMeetings from '../Components/Meetings/SearchMeetings';
// import HomeGroup from '../Components/Meetings/HomeGroup';
// import AddMeeting from '../Components/Meetings/AddMeetings'
// import HomeGroupMeetingDetails from '../Components/Meetings/HomeGroupMeetingDetails'
// import Links from '../Components/Meetings/Links';
// import VoiceCallingScreen from '../Components/Meetings/VoiceCallingScreen'
// import AddSponser from '../Components/StepWorkForm/SponseesAndSponsors/AddSponser'
// import AdminGroupMembers from '../Components/MyGroup/AdminGroupMembers'
// import RecoveryCelebrations from '../Screens/RecoveryCelebrations/RecoveryCelebrations'
// import SponseesStepWork from '../Components/StepWorkForm/SponseesAndSponsors/SponseesStepWork'
// import SponseeStepWorkDetails from '../Components/StepWorkForm/SponseesAndSponsors/SponseeStepWorkDetails'
// import IsAdminSubCategory from '../Components/marketplace/IsAdminSubCategory'
// import ShowMeeting from '../Components/Meetings/ShowMeeting'
// import ShowGoogleMap from '../Components/Meetings/ShowGoogleMap'
// import ShowDirection from '../Components/Meetings/ShowDirection'
// import ScrollToTop from '../Components/ScrollToTop/ScrollToTop'
import FallbackComponent from '../Components/FallbackComponent/FallbackComponent';
import AppDeleteProfile from '../Components/AppDeleteProfile/index.js';

const HomeScreen = lazy(() => import("../Screens/HomeScreen/HomeScreen"));
const Step_work = lazy(() => import('../Components/More/Step_Work/Step_work'));
const Notification = lazy(() => import('../Components/Push_Notification/Notification'));
const RussellBrand = lazy(() => import('../Components/StepWorkForm/RussellBrand'));
const RusselRecoveryOne = lazy(() => import('../Components/StepWorkForm/RussellBrand/RusselRecoveryThree'));
const RusselRecoveryTwo = lazy(() => import('../Components/StepWorkForm/RussellBrand/RusselRecoveryTwo'));
const StepOne = lazy(() => import('../Components/StepWorkForm/StepOneTwoTh/StepOne'));
const TwelveSteps = lazy(() => import('../Components/StepWorkForm/TwelveStep/index'));
const TwellStepsWorkSheet = lazy(() => import('../Components/StepWorkForm/TwelveStepsWorkSheet/index'));
const VideoCall = lazy(() => import('../Screens/MeetingRoomsScreen/VideoCall'));
const ProfileScreen = lazy(() => import('../Screens/ProfileSreen/ProfileScreen'));
const Nearby_People_Screen = lazy(() => import('../Screens/Nearby_People_Screen/Nearby_People_Screen'));
const LeaderBoardScreen = lazy(() => import('../Screens/LeaderBoardScreen/LeaderBoardScreen'));
const My_Recovery_Capital_Score_Screen = lazy(() => import('../Screens/My_Recovery_Capital_Score_Screen/My_Recovery_Capital_Score_Screen'));
// const Login = lazy(()=>import('../Components/Login/Login'));
// const SignUp = lazy(()=>import('../Screens/auth/signUp/SignUp.js'));
const ForgotPassword = lazy(() => import('../Screens/auth/forgotPassword/ForgotPassword'));
const MeetingRoomsScreens = lazy(() => import('../Screens/MeetingRoomsScreen/MeetingRoomsScreen'));
const MeetingJoin = lazy(() => import('../Components/Meeting/MeetingJoin'));
const MeetingChannel = lazy(() => import('../Components/Meeting/MeetingChannel'));
const ContactUs = lazy(() => import('../Components/contact/ContactUs'));
const BlockedUsers = lazy(() => import('../Components/block/BlockedUsers'));
const ChangePassword = lazy(() => import('../Components/change_password/ChangePassword'));
const SpeakerCategory = lazy(() => import('../Components/speaker_tapes/SpeakerCategory'));
const SpeakerTape = lazy(() => import('../Components/speaker_tapes/SpeakerTape'));
const Tape = lazy(() => import('../Components/speaker_tapes/Tape'));
const RecoveryBirthday = lazy(() => import('../Components/recovery_birthday/RecoveryBirthday'));
const MarketplaceCategory = lazy(() => import('../Components/marketplace/MarketplaceCategory'));
const MarketplaceSubCategory = lazy(() => import('../Components/marketplace/MarketplaceSubCategory'));
const OnlineCoaching = lazy(() => import('../Components/coach/OnlineCoaching'));
const RecoveryCapitalPlan = lazy(() => import('../Components/my_recovery_capital_plan/RecoveryCapitalPlan'));
const AddActivityGoal = lazy(() => import('../Components/my_recovery_capital_plan/AddActivityGoal'));
const HistoryActivityGoal = lazy(() => import('../Components/my_recovery_capital_plan/HistoryActivityGoal'));
const SupportCircle = lazy(() => import('../Screens/SupportCircle/SupportCircle'));
const LGBTQ = lazy(() => import('../Screens/LGBTQ/LGBTQ'));
const SupportCircleCreate = lazy(() => import('../Screens/SupportCircle/SupportCircleCreate'));
const TermsAndConditions = lazy(() => import('../Screens/TermsAndConditions/TermsAndConditons'));
const MyGroupScreen = lazy(() => import('../Screens/MyGroupScreen/MyGroupScreen'));
const PrivacyPolicy = lazy(() => import('../Screens/PrivacyPolicy/PrivacyPolicy'));
const MyProfileScreen = lazy(() => import('../Screens/MyProfileScreen.js/MyProfileScreen'));
const LeaderBoardGamesDetails = lazy(() => import('../Components/Leader_boards_games/Leader_boards_games_list/LeaderBoardGamesDetails'));
const Followerlist = lazy(() => import('../Components/GetFollowerListOfUser/Followerlist'));
const GetFollowingListOfUser = lazy(() => import('../Components/GetFollowingListOfUser/GetFollowingListOfUser'));
const AboutBetterApp = lazy(() => import('../Components/AboutBetterApp/AboutBetterApp'));
const DetailAboutCategory = lazy(() => import('../Components/marketplace/DetailAboutCategory'));
const CreateClassified = lazy(() => import('../Components/marketplace/CreateClassified'));
const AddPost = lazy(() => import('../Components/Profile/Add_Post/AddPost'));
const EditProfile = lazy(() => import('../Components/Profile/EditProfile/EditProfile'));
const FellowShipCheckBox = lazy(() => import('../Components/Profile/FellowShipCheckBox/FellowShipCheckBox'));
const DailyJournal = lazy(() => import('../Screens/DailyJournal/DailyJournal'));
const EditGoal = lazy(() => import('../Components/my_recovery_capital_plan/EditActivityGoal'));
const SupportCircleChat = lazy(() => import('../Screens/SupportCircle/SupportCircleChat'));
const FollowingUserPost = lazy(() => import('../Components/Recovery_Wall/FollowingUserPost'));
const GroupList = lazy(() => import('../Components/MyGroup/GroupList'));
const JoinGroup = lazy(() => import('../Components/MyGroup/JoinGroup'));
const GroupPost = lazy(() => import('../Components/MyGroup/GroupPost'));
const SponseesAndSponsors = lazy(() => import('../Components/StepWorkForm/SponseesAndSponsors'));
const ChatList = lazy(() => import('../Components/Chat/ChatList'));
const Message = lazy(() => import('../Components/Chat/Message'));
const Meetings = lazy(() => import('../Components/Meetings/Meeting_index'));
const LocateMeetings = lazy(() => import('../Components/Meetings/LocateMeetings'));
const SearchMeetings = lazy(() => import('../Components/Meetings/SearchMeetings'));
const HomeGroup = lazy(() => import('../Components/Meetings/HomeGroup'));
const AddMeeting = lazy(() => import('../Components/Meetings/AddMeetings'));
const HomeGroupMeetingDetails = lazy(() => import('../Components/Meetings/HomeGroupMeetingDetails'));
const Links = lazy(() => import('../Components/Meetings/Links'));
const VoiceCallingScreen = lazy(() => import('../Components/Meetings/VoiceCallingScreen'));
const AddSponser = lazy(() => import('../Components/StepWorkForm/SponseesAndSponsors/AddSponser'));
const AdminGroupMembers = lazy(() => import('../Components/MyGroup/AdminGroupMembers'));
const RecoveryCelebrations = lazy(() => import('../Screens/RecoveryCelebrations/RecoveryCelebrations'));
const SponseesStepWork = lazy(() => import('../Components/StepWorkForm/SponseesAndSponsors/SponseesStepWork'));
const SponseeStepWorkDetails = lazy(() => import('../Components/StepWorkForm/SponseesAndSponsors/SponseeStepWorkDetails'));
const IsAdminSubCategory = lazy(() => import('../Components/marketplace/IsAdminSubCategory'));
const ShowMeeting = lazy(() => import('../Components/Meetings/ShowMeeting'));
const ShowGoogleMap = lazy(() => import('../Components/Meetings/ShowGoogleMap'));
const ScrollToTop = lazy(() => import('../Components/ScrollToTop/ScrollToTop'));
const RusselRecoveryThree = lazy(() => import('../Components/StepWorkForm/RussellBrand/RusselRecoveryThree'));

const StateChange = () => {
  const [IsLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2500)

  }, [])
  return (
    <Suspense fallback={<FallbackComponent />}>
      {
        IsLoading ? <Loading /> :

          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>

              <Route exact path="/signup">
                <SignUp />
              </Route>

              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>

              <Route exact path="/meetingRoom" >
                <MeetingRoomsScreens />
              </Route>

              <Route exact path="/terms-and-conditions">
                <TermsAndConditions />
              </Route>

              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>

              <Route exact path="/delete-account">
                <AppDeleteProfile />
              </Route>

              {/* <Header /> */}
              <PrivateRoute component={HomeScreen} path="/" exact />
              <PrivateRoute component={Nearby_People_Screen} path="/nearby_people" exact />
              <PrivateRoute component={Followerlist} path="/get-follower" exact />
              <PrivateRoute component={GetFollowingListOfUser} path="/get-following-user" exact />
              <PrivateRoute component={MeetingJoin} path="/recovery_rooms" exact />
              <PrivateRoute component={MeetingChannel} path="/recovery_room/:name" exact />
              <PrivateRoute component={Step_work} path="/step_work" exact />
              <PrivateRoute component={LeaderBoardGamesDetails} path="/Leader_Board_games_Details" exact />
              <PrivateRoute component={StepOne} path="/step_work/step_one" exact />
              <PrivateRoute component={TwelveSteps} path="/step_work/twelve_step" exact />
              <PrivateRoute component={RussellBrand} path="/step_work/russell_brand" exact />
              <PrivateRoute component={TwellStepsWorkSheet} path="/step_work/twelve_steps_worksheet" exact />
              <PrivateRoute component={SponseesAndSponsors} path="/step_work/sponsees_and_sponsors" exact />
              {/* <PrivateRoute component={StepOneTwoTh} path="/step_work_forms" exact />
                            <PrivateRoute component={Step_Three} path="/step_work_forms_last" exact /> */}
              <PrivateRoute component={My_Recovery_Capital_Score_Screen} path="/my_recovery_capital_score" exact />
              <PrivateRoute component={RusselRecoveryOne} path="/russel_recovery_program_first" exact />
              <PrivateRoute component={RusselRecoveryTwo} path="/russel_recovery_program_two" exact />
              <PrivateRoute component={RusselRecoveryThree} path="/russel_recovery_program_three" exact />

              <PrivateRoute component={LeaderBoardScreen} path="/leader_boards_games_users_list" exact />
              <PrivateRoute component={Notification} path="/web_notification_web" exact />
              <PrivateRoute component={ProfileScreen} path="/profile" exact />
              <PrivateRoute component={ContactUs} path="/contact-us" exact />
              <PrivateRoute component={AboutBetterApp} path="/about" exact />
              <PrivateRoute component={MyProfileScreen} path="/my_profile" exact />
              <PrivateRoute component={MyGroupScreen} path="/group" exact />
              <PrivateRoute component={DailyJournal} path="/daily-journal" exact />
              <PrivateRoute component={EditGoal} path="/edit-goal-activity" exact />
              <PrivateRoute component={FollowingUserPost} path="/following_user_posts" exact />
              <PrivateRoute component={SponseesStepWork} path="/sponsees_step_detail" exact />
              <PrivateRoute component={SponseeStepWorkDetails} path="/sponsee_work_details" exact />
              {/* <PrivateRoute component={SupportCircleGroupDetails} path="/support_group_detail" exact /> */}
              <PrivateRoute component={ChatList} path="/chat" exact />
              <PrivateRoute component={VoiceCallingScreen} path="/live-voice-call" exact />
              <PrivateRoute component={Message} path="/chat/messages" exact />
              <PrivateRoute component={HomeGroupMeetingDetails} path="/meeting_details" exact />
              <PrivateRoute component={BlockedUsers} path="/blocked_users" exact />
              <PrivateRoute component={AddPost} path="/add-post" exact />
              <PrivateRoute component={EditProfile} path="/edit-profile" exact />
              <PrivateRoute component={AdminGroupMembers} path="/Admin_Group_Members" exact />
              <PrivateRoute component={ChangePassword} path="/change_password" exact />
              <PrivateRoute component={SpeakerCategory} path="/speaker_tapes" exact />
              <PrivateRoute component={SpeakerTape} path="/speaker_tape" exact />
              <PrivateRoute component={Tape} path="/tape" exact />
              <PrivateRoute component={RecoveryBirthday} path="/my_recovery_birthday" exact />
              <PrivateRoute component={MarketplaceCategory} path="/marketplace" exact />
              <PrivateRoute component={MarketplaceSubCategory} path="/marketplace/category" exact />
              <PrivateRoute component={IsAdminSubCategory} path="/subcategory" exact />
              <PrivateRoute component={OnlineCoaching} path="/online_coaching" exact />
              <PrivateRoute component={RecoveryCapitalPlan} path="/my_recovery_capital_plan" exact />
              <PrivateRoute component={AddActivityGoal} path="/add-activity-goal" exact />
              <PrivateRoute component={HistoryActivityGoal} path="/history-activity-goal" exact />
              <PrivateRoute component={MeetingRoomsScreens} path="/meetingRoom" exact />
              <PrivateRoute component={VideoCall} path="/videoCall" exact />
              <PrivateRoute component={FellowShipCheckBox} path="/fellowShip-list" exact />
              <PrivateRoute component={LGBTQ} path="/lgbtq" exact />
              <PrivateRoute component={SupportCircle} path="/support_circle" exact />
              <PrivateRoute component={SupportCircleCreate} path="/support_circle/support-circle-create" exact />
              <PrivateRoute component={SupportCircleChat} path="/support_circle/support-circle-chat" exact />
              <PrivateRoute component={DetailAboutCategory} path="/detail-about-category" exact />
              <PrivateRoute component={CreateClassified} path="/create-classified" exact />
              <PrivateRoute component={GroupList} path="/group_list" exact />
              <PrivateRoute component={JoinGroup} path="/join_group" exact />
              <PrivateRoute component={GroupPost} path="/admin_group_post" exact />
              <PrivateRoute component={Meetings} path="/meetings" exact />
              <PrivateRoute component={LocateMeetings} path="/locate_meetings" exact />
              <PrivateRoute component={SearchMeetings} path="/search_meetings" exact />
              <PrivateRoute component={HomeGroup} path="/my_homegroup" exact />
              <PrivateRoute component={AddMeeting} path="/add_meeting" exact />
              <PrivateRoute component={RecoveryCelebrations} path="/recovery_celebrations" exact />
              <PrivateRoute component={Links} path="/links" exact />
              <PrivateRoute component={AddSponser} path="/add_sponser" exact />
              <PrivateRoute component={ShowMeeting} path="/meeting_list" exact />
              <PrivateRoute component={ShowGoogleMap} path="/google-map" exact />
              {/* <PrivateRoute component={ShowDirection} path="/meeting-direction" exact /> */}
            </Switch>

          </Router>
      }
    </Suspense >
  )
}

export default StateChange