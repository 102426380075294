import React from 'react'
import { Col, Row, Container, Spinner, Nav } from 'react-bootstrap'
// import Ads from '../Ads/Ads'
import SideNavBar from '../SideNavBar/SideNavBar'
// import styles from '../../Screens/HomeScreen/HomeScreen.module.css'
import Ads from '../Ads/Ads'
import recoveryWall from '../Assets/sidebar/wall@3x.png';
import recoveryCapital from '../Assets/sidebar/plan@3x.png';
import recoveryScore from '../Assets/sidebar/capital_plan.png';
import recoveryRoom from '../Assets/sidebar/recoveryclock.svg';
import supportCircle from '../Assets/sidebar/supportcircle.png';
import peopleNear from '../Assets/sidebar/nearby.png';
import marketPlace from '../Assets/sidebar/classified.png';
import recoveryBirth from '../Assets/sidebar/recoveryclock.png';
import speakerTapes from '../Assets/sidebar/speakertapes.png';
import Meetings from '../Assets/sidebar/meetings.png';
import Groups from '../Assets/sidebar/groups.png';
import stepWork from '../Assets/sidebar/recoveryclock@3x.png';
import onlineCoaching from '../Assets/sidebar/online_class@3x.png';
import Lgbtq from '../Assets/sidebar/lgbt_support@3x.png';
import getHelp from '../Assets/sidebar/help@3x.png';
import leaderBoard from '../Assets/sidebar/leader_board@3x.png';
import styles from '../SideNavBar/SideNavBar.module.css'

function FallbackComponent() {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={3}>
                        {/* <SideNavBar /> */}
                        {/* <SideNavBar/> */}
                        <div className={styles.sidebarBg}>
                            <Nav className="flex-column sidebar" variant='pills' >
                                <Nav.Link eventKey="link-1"><img src={recoveryWall}></img> &nbsp;My Recovery Wall</Nav.Link>
                                <Nav.Link eventKey="link-2"><img src={recoveryCapital}></img> &nbsp;My Recovery Capital Score</Nav.Link>
                                <Nav.Link eventKey="link-3"><img src={recoveryScore}></img> &nbsp;My Recovery Capital Plan</Nav.Link>
                                <Nav.Link eventKey="link-4"><img src={recoveryRoom}></img> &nbsp; Recovery Rooms</Nav.Link>
                                {/* <Nav.Link onClick={() => setOpen(false)} eventKey="link-5">Safety Net</Nav.Link> */}
                                <Nav.Link eventKey="link-6"><img src={supportCircle}></img> &nbsp;Support Circles</Nav.Link>
                                <Nav.Link eventKey="link-7"> <img src={peopleNear}></img> &nbsp;People Nearby</Nav.Link>
                                <Nav.Link eventKey="link-8"><img src={marketPlace}></img> &nbsp;Marketplace</Nav.Link>
                                <Nav.Link eventKey="link-9"><img src={recoveryBirth}></img> &nbsp;My Recovery Birthday</Nav.Link>
                                <Nav.Link eventKey="link-10"><img src={speakerTapes}></img> &nbsp;Speaker Tapes</Nav.Link>
                                <Nav.Link eventKey="link-11"><img src={Meetings}></img> &nbsp;Meetings</Nav.Link>
                                <Nav.Link eventKey="link-12"><img src={Groups}></img> &nbsp; My Groups</Nav.Link>
                                <Nav.Link
                                    // onClick={() => history.push({ pathname: '/leader_boards_games_users_list', state: { active: 21 } })} 

                                    eventKey="link-23"
                                ><img src={leaderBoard}></img> &nbsp;Recovery Celebrations</Nav.Link>
                                <Nav.Link eventKey="link-13"><img src={stepWork}></img> &nbsp;Step Work</Nav.Link>

                                <Nav.Link eventKey="link-19"><img src={onlineCoaching}></img> &nbsp;Online Coaching</Nav.Link>
                                <Nav.Link eventKey="link-22"><img src={Lgbtq}></img> &nbsp;LGBTQ Support</Nav.Link>
                                <Nav.Link href="https://www.myrecoveryplan.ca/get-help/" eventKey="link-20"><img src={getHelp}></img> &nbsp;Get Help Now</Nav.Link>
                                <Nav.Link eventKey="link-21"><img src={leaderBoard}></img> &nbsp;Leader Boards Games</Nav.Link>


                            </Nav>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '700px',
                            }}
                        >
                            <Spinner animation="border" variant="custom" />
                        </div>
                    
                    </Col>
                    <Col lg={3}>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default FallbackComponent