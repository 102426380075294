import React from 'react'
import Styles from './Loading.module.css'
const IconImage = require('../Assets/BetterAppIcon_PNG.png')

const Loading = () => {
    return (
        <div className={Styles.Loading}>
            <div className={Styles.Loading_Content}>
                <div className={Styles.IsLoadingScreen}>
                    <img
                        src={IconImage}
                        className={Styles.IconImage}
                        alt="logo"
                    /> 
                    <h1 className={Styles.Loading_Text}>
                        Feel <span className={Styles.spanText}> Better </span> Today
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Loading