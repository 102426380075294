export const LogOutUser = () => dispatch => new Promise((resolve, reject) => {
   
    var requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            // 'Connection':'upgrade'
        },
    };
    
    let userId = localStorage.getItem("userId") || 11846
    fetch(`${process.env.REACT_APP_API_BASE_URL}/LogoutUser?UserID=${userId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            resolve(result);
        })
        .catch(error => {reject(error)});
})