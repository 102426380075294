import React, { useState } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Logo from '../Assets/BetterAppIcon_PNG.png';
import { IoAlert } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://betterapp.ca/">
        BetterApp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [termCondition, setTermCondition] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [termConditionError, setTermConditionError] = useState(false);
  const [responseMessage , setResponseMessage] = useState();

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    if (!username) {
      setNameError(true);
      setLoader(false);
    }
    if (!password) {
      setPasswordError(true);
      setLoader(false);
    }
    if (!termCondition) {
      setTermConditionError(true);
      setLoader(false);   
    }
    
    else{
      const credential = new URLSearchParams();
      credential.append('Username', username);
      credential.append('Password', password);
      credential.append('DeviceID', "string");
      credential.append('DeviceToken', 'string');
      credential.append('isPaid', 1);
      credential.append('DeviceType', 'web');

      fetch(`${process.env.REACT_APP_API_BASE_URL}/LoginUser`, {
        method: "POST",
        headers:{
          "Content-Type": "application/x-www-form-urlencoded"         
        },
        body: credential
      })
      .then((response)=>response.json())
        .then(result => {

          if (result.ResponseDetails.ErrCode === 0) {
            localStorage.setItem("userId", result.userDetails.UserID)
            localStorage.setItem("userData", JSON.stringify(result))
            history.push("/");
          }
          else {
            setResponseMessage(result.ResponseDetails.ErrString[0]);
            toast.error(`${result.ResponseDetails.ErrString[0]}`);
            setLoader(false);
          }
        }).catch((error)=>{
          console.log(error);
        })
    }
      
   
  }
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: '#fff',
          margin: '0px auto',
        }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: 'flex-one',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={Logo} width={80} height={80} className="mx-auto d-block" alt='logo'></img>
            <Typography component="h3" variant="h5" className='text-center my-3'>
              Sign in
            </Typography>
            <Box component="form" onSubmit={(event) => handleSubmit(event)} noValidate sx={{ mt: 1 }}>

              <TextField
                margin="normal"
                required
                fullWidth
                id="Username"
                label="Enter your Username"
                name="Username"
                // autoComplete="Username"
                autoFocus
                onChange={(e) => {
                  setNameError(false)
                  setUsername(e.target.value)
                }}
                style={{ overflow: "visible" }}
              />
              {
                nameError
                  ?
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IoAlert style={{ color: "red" }}></IoAlert>
                    <span style={{ fontSize: "12px", color: "red" }}>Please enter the name</span>
                  </div>
                  :
                  ""
              }
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => {
                  setPasswordError(false)
                  setPassword(e.target.value);
                }}
                style={{ overflow: "visible" }}
              />
              {
                passwordError
                  ?
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IoAlert style={{ color: "red" }}></IoAlert>
                    <span style={{ fontSize: "12px", color: "red" }}>Please enter the password</span>
                  </div>
                  :
                  ""
              }
              <Grid container>
                <Grid item xs>
                  <Checkbox
                    value="Agree"
                    color="primary"
                    onClick={() => {
                      setTermConditionError(false)
                      setTermCondition(!termCondition)
                    }}
                  />
                  Please agree to our <Link href="https://betterapp.ca/index.php/terms-and-conditions-of-use/" target="_blank" style={{cursor:'pointer'}}>T&C</Link> and <Link href="https://betterapp.ca/index.php/privacy-policy-2/" target="_blank" style={{cursor:'pointer'}}>Privacy policy</Link>
                </Grid>
              </Grid>

              {
                termConditionError
                  ?
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IoAlert style={{ color: "red" }}></IoAlert>
                    <span style={{ fontSize: "12px", color: "red" }}>Please check the box</span>
                  </div>
                  :
                  ""
              }


              <Button
                disabled={loader}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loader ? 'Signing...' : 'Sign In'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link style={{ cursor: "pointer" }} onClick={() => history.push('/forgot-password')} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link style={{ cursor: "pointer" }} onClick={() => history.push('/signup')} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {
        responseMessage
          ? 
          <ToastContainer />
          :
          ""
      }
          <Copyright sx={{ mt: 2, mb: 0 }} />
        </Container>
      </ThemeProvider>
    )
  }