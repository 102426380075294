import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import Logo from '../../../Components/Assets/BetterAppIcon_PNG.png';
import { useHistory, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { disableDates } from '../../../Components/TimeFunction/disableDates';
export default function SignUp() {

  const history = useHistory();

  const [formData, setFormData] = useState({
    profileType: 1,
    username: "",
    photoName: "",
    Base64String: "",
    birthDate: "",
    recoveryDate: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    tcAgree: false
  })

  const [spinner, setSpinner] = useState(false)

  const [profileTypeError, setProfileTypeError] = useState(false)

  const [usernameError, setUsernameError] = useState(false)

  const [photoNameError, setPhotoNameError] = useState(false)

  const [birthDateError, setBirthDateError] = useState(false)

  const [recoveryDateError, setRecoveryDateError] = useState(false)

  const [emailError, setemailError] = useState(false)

  const [confirmEmailError, setConfirmEmailError] = useState(false)

  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  const [tcAgreeError, setTcAgreeError] = useState(false);

  const [passwordSameError, setPasswordSameError] = useState(false);

  const [emailSameError, setEmailSameError] = useState(false);

  const [responseMessage, setResponseMessage] = useState(false);

  const [recoverydate, setRecoverydate] = useState(false);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result.split(",")[1]);

    }
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  });



  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.username) {
      setUsernameError(true);
    }

    if (!formData.email) {
      setemailError(true);
    }


    if (!formData.confirmPassword) {
      setConfirmPasswordError(true);
    }

    if (!formData.password) {
      setPasswordError(true);
    }

    if (!formData.confirmEmail) {
      setConfirmEmailError(true);
    }

    if (!formData.profileType) {
      setProfileTypeError(true);
    }

    if (!formData.recoveryDate) {
      setRecoveryDateError(true);
    }

    if (!formData.birthDate) {
      setBirthDateError(true);
    }

    if (!formData.tcAgree) {
      setTcAgreeError(true);
    }

    if (!formData.photoName) {
      setPhotoNameError(true)
    }
    if (formData.email !== formData.confirmEmail) {
      setEmailSameError(true)
    }
    if (formData.password !== formData.confirmPassword) {
      setPasswordSameError(true)
    }

    if (formData.username && formData.Base64String && formData.email && formData.password && formData.birthDate && formData.email && formData.photoName && formData.profileType && formData.email === formData.confirmEmail && formData.password == formData.confirmPassword) {

      setSpinner(true);
      const credential = new URLSearchParams()
      credential.append('Username', formData.username);
      credential.append('Base64String', formData.Base64String);
      credential.append('Email', formData.email);
      credential.append('Password', formData.password);
      credential.append('Birthday', new Date(formData.birthDate).toISOString().split("T")[0]);
      credential.append('DeviceType', "web");
      credential.append('DeviceToken', 'string');
      credential.append('DeviceID', 1);
      credential.append('IsPaid', 1);
      credential.append('FacebookId', 1);
      credential.append('FriendsId', formData.email);
      credential.append('recoveryBirthDay', formData.recoveryDate ? new Date(formData.recoveryDate).toISOString().split("T")[0] : "");
      credential.append('FileName', formData.photoName);
      credential.append('AppleId', 'string');
      credential.append('UserType', formData.profileType);

      fetch(`${process.env.REACT_APP_API_BASE_URL}/RegisterUser`, {
        method: "POST",
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        body: credential
      })
        .then(response => response.json())
        .then(result => {
          if (result.ResponseDetails.ErrCode === 0) {
            setResponseMessage(true);
            toast.success("Account is successfully created");
            setSpinner(false)
            setTimeout(() => {
              history.push('/login');
            }, 3000);

          }
          else {

            setResponseMessage(true);
            toast.error(`${result.ResponseDetails.ErrString[0]}`);
            setSpinner(false);
          }

        })
        .catch(error => {
          console.log('error', error)
        });


    }

  }

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      history.push('/')
    }
  }, [])

  const recoveryDate = () => {
    setRecoverydate(true)
  }

  const recoveryDateChange = () => {
    setRecoverydate(false)
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl="7" lg="8" md="11" >
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Card className="my-4">
              <Card.Body className="p-4">
                <Row className="text-center">
                  <Col xs={12}>
                    <img src={Logo} width={80} height={80} className="mx-auto d-block"></img>
                  </Col>
                  <Col xs={12}>
                    <h3 className='my-3'>SignUp</h3>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="profileType">
                  <Form.Label>What's your profile type?</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Are you in recovery?"
                      name="group1"
                      type="radio"
                      id="profileType1"
                      defaultChecked
                      onClick={() => { recoveryDateChange() }}
                      onChange={(e) => {
                        setFormData({ ...formData, profileType: 1 });
                        setProfileTypeError(false);
                      }
                      }
                    />
                    <Form.Check
                      inline
                      label="A person who uses drugs."
                      name="group1"
                      type="radio"
                      id="profileType2"
                      onClick={() => { recoveryDate() }}
                      onChange={(e) => {
                        setFormData({ ...formData, profileType: 2 });
                        setProfileTypeError(false);
                      }
                      }
                    />
                    <Form.Check
                      inline
                      label="A recovery ally?"
                      name="group1"
                      type="radio"
                      id="profileType3"
                      onClick={() => { recoveryDate() }}
                      onChange={(e) => {
                        setFormData({ ...formData, profileType: 3 });
                        setProfileTypeError(false);
                      }
                      }
                    />
                  </div>

                  {
                    profileTypeError
                      ?
                      <span
                        style={{ fontSize: "12px", color: "red" }}>
                        ! Please choose the profile type
                      </span>
                      :
                      ""
                  }
                </Form.Group>
                <Form.Group className="mb-3" controlId="userName">
                  <Form.Label>Add your Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    onChange={(e) => {
                      setUsernameError(false)
                      setFormData({ ...formData, username: e.target.value })
                    }} />
                  {
                    usernameError
                      ?
                      <span
                        style={{ fontSize: "12px", color: "red" }}>
                        ! Please enter the username
                      </span>
                      :
                      ""
                  }
                </Form.Group>


                <Form.Group className="mb-3" controlId="add-photo">
                  <Form.Label>Add your first Photo</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={async (e) => {
                      setPhotoNameError(false)
                      const photoBase64 = await toBase64(e.target.files[0])
                      setFormData({ ...formData, Base64String: photoBase64, photoName: e.target.files[0].name })
                    }
                    } />
                  {
                    photoNameError
                      ?

                      <span style={{ fontSize: "12px", color: "red" }}>Please add the profile</span>

                      :
                      ""
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="birtday">
                  <Form.Label>When's your Birthday?</Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      type="date"
                      onChange={(e) => {
                        setBirthDateError(false)
                        setFormData({ ...formData, birthDate: e.target.value });
                      }}
                      max={disableDates()}
                    />
                  </div>
                  {
                    birthDateError
                      ?

                      <span
                        style={{ fontSize: "12px", color: "red" }}
                      > ! Please enter the birth date
                      </span>

                      :
                      ""
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="birthday" style={{ display: recoverydate ? "none" : "block" }}>
                  <Form.Label>When's your recovery date?</Form.Label>
                  <div className='position-relative'>
                  <Form.Control
                      type="date"
                      onChange={(e) => {
                        setBirthDateError(false)
                        setFormData({ ...formData, recoveryDate: e.target.value });
                      }}
                      max={disableDates()}
                    />
                  </div>
                  {
                    recoveryDateError
                      ?

                      <span
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        ! Please enter the recovery date
                      </span>

                      :
                      ""
                  }
                </Form.Group>
                <Row>
                  <Form.Group controlId="email">
                    <Form.Label>What's your E-mail address?</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="email">
                    <Form.Control type="email" placeholder="E-mail" onChange={(e) => {
                      setemailError(false);
                      setEmailSameError(false);
                      setFormData({ ...formData, email: e.target.value });
                    }
                    } />
                    {
                      emailError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          ! Please enter the email
                        </span>

                        :
                        ""
                    }

                    {
                      emailSameError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          ! Email should be same
                        </span>

                        :
                        ""
                    }
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="confirm-email">
                    <Form.Control type="email" placeholder="Confirm E-mail" onChange={(e) => {
                      setConfirmEmailError(false);
                      setEmailSameError(false);
                      setFormData({ ...formData, confirmEmail: e.target.value });
                    }
                    } />
                    {
                      confirmEmailError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}>
                          ! Please enter the confirm email
                        </span>

                        :
                        ""
                    }
                  </Form.Group>
                </Row>


                <Row>
                  <Form.Group controlId="email">
                    <Form.Label>Choose your password?</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3" controlId="password">
                    <Form.Control type="password" placeholder="Password" onChange={(e) => {
                      setPasswordError(false)
                      setPasswordSameError(false)
                      setFormData({ ...formData, password: e.target.value })
                    }
                    } />
                    {
                      passwordError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          ! Please enter the password
                        </span>

                        :
                        ""
                    }

                    {
                      passwordSameError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          Password should be same
                        </span>

                        :
                        ""
                    }
                  </Form.Group>



                  <Form.Group as={Col} className="mb-3" controlId="confirm-password" >
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        setConfirmPasswordError(false)
                        setFormData({ ...formData, confirmPassword: e.target.value })
                      }
                      }
                    />

                    {
                      confirmPasswordError
                        ?

                        <span
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          ! Please enter the confirm password
                        </span>

                        :
                        ""
                    }
                  </Form.Group>

                </Row>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <div className="d-flex">
                    <Form.Check
                      type="checkbox"
                      className='me-2'
                      onChange={(e) => {
                        setTcAgreeError(false);
                        setFormData({ ...formData, tcAgree: !formData.tcAgree });
                      }
                      }
                    />
                    <p className="mb-0">Please agree to our <a href="https://betterapp.ca/index.php/terms-and-conditions-of-use/" target="_blank">T&C</a> and <a href="https://betterapp.ca/index.php/privacy-policy-2/" target="_blank">Privacy policy</a></p>
                  </div>
                  {
                    tcAgreeError
                      ?


                      <span
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        ! Please accept term and condition
                      </span>

                      :
                      ""
                  }
                </Form.Group>

                <Row className="justify-content-center">
                  <Col md="4">
                    <div className="d-grid gap-2">

                      <Button disabled={spinner} variant="custom" type="submit" onSubmit={(event) => handleSubmit(event)}
                      //  { `${spinner}` ? disabled : ""}
                      >
                        {
                          spinner
                            ?
                            <div>
                              <Spinner animation="border" variant="custom" style={{ width: "1rem", height: " 1rem" }} /> Register Now
                            </div>
                            :
                            "Register Now"
                        }
                      </Button>

                    </div>
                  </Col>
                  <Col xs="12">
                    <p className='text-center'>Already Registered? <Link to="/login">Log In</Link></p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      </Row>
      {
        responseMessage
          ?
          <ToastContainer />
          :
          ""
      }
    </Container>
  )
}

