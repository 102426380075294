import React from 'react'
import styles from './Ads.module.css'

const Ads = () => {
    return (
        <div className={styles.container}>
            <div className={styles.ad_container}>
                <img
                    src={require('../Assets/prize1000.png')}
                    alt="ad"
                    className={styles.ad}
                />

                <img
                    src={require('../Assets/prize_50.png')}
                    alt="ad"
                    className={styles.ad}
                />
            </div>
        </div>
    )
}

export default Ads