import React from 'react'
import './App.css';
import StateChange from './StateChange/StateChange';
// import { ChatEngine } from 'react-chat-engine';

function App() {
  return (
      <div className="App">
        <StateChange />
      </div> 
  );
}
export default App;