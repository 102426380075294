import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Header from '../../Components/Header/Header'
import SideNavBar from "../../Components/SideNavBar/SideNavBar";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const userId = localStorage.getItem("userId");

    return (
        <Route {...rest} render={props => (
            userId ?
                <>
                    <Header />
                    <Component {...props} />
                </>
                : <Redirect to="/login" />
        )} />
    )
}


export default PrivateRoute;