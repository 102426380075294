export const GET_POST = 'GET_POST';
export const GET_STORY = 'GET_STORY';
export const MCQ_QUESTION_DATA = 'MCQ_QUESTION_DATA';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const NEARBY_PEOPLE = 'NEARBY_PEOPLE';
export const LOGIN = "LOGIN";
export const GROUP_DATA = "GROUP_DATA";
export const BIRTHDAYS_DATA = "BIRTHDAYS_DATA";
export const USERS_DATA = "USERS_DATA";
export const LGBTQ_MEETINGS = "LGBTQ_MEETINGS";
export const SIGNUP = "SIGNUP";
export const LIKES = "LIKES";
export const GET_LOGIN_USER_DETAIL = "GET_LOGIN_USER_DETAIL";
export const FOLLOWER_LIST = "FOLLOWER_LIST";
export const FOLLOWING_LIST = "FOLLOWING_LIST";
export const SET_PHOTO = "SET_PHOTO";
export const FILE_NAME = "FILE_NAME";
export const FEED_TYPE = "FEED_TYPE";
export const GET_FELLOWSHIP = "GET_FELLOWSHIP";
export const COMMENT = "COMMENT";
export const PARTICULAR_GROUP_DETAIL = "PARTICULAR_GROUP_DETAIL";
export const GET_MESSAGE_DETAILS = "GET_MESSAGE_DETAILS";
export const CONCATE_CHAT = "CONCATE_CHAT";